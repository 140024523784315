import React, { useEffect, useState } from 'react'
import { useLocation, navigate } from "@reach/router"
import Layout from '../components/Layout/layout'
import { graphql, Link } from 'gatsby'
import { cloneDeep, lowerCase, orderBy } from 'lodash' 
import GeneralPageSubHeader from '../components/GeneralPageSubHeader/general-page-subheader'
import SectionTitle from '../components/SectionTitle/section-title'
import PageIntro from '../components/PageIntro/page-intro'
import AllBlogPosts from '../components/AllBlogPosts/all-blog-posts'
import BlogNewsLetter from '../components/BlogPageNewsLetter/BlogPageNewsLetter'
import { urlToBlogSearchState } from "../common/utils";
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import Glass from '../images/magnifying-glass.svg' 
import SeoHelmet from '../components/SeoHelmet/seo-helmet'

import "../pages/blogs.scss"


export const query = graphql` 
    {
        blog: allContentfulGeneralTitleIntroSection(filter: {title: {eq: "Our Blog"}}) {
            edges {
                node {
                    id
                    title
                    titleFirstBreak
                    titleSecondBreak
                    introText {
                        raw
                    }
                    pageHeaderImage {
                        gatsbyImageData(
                            aspectRatio: 1.5
                            cropFocus: BOTTOM
                            layout: FULL_WIDTH
                            placeholder: BLURRED
                          )
                          title
                    }
                    bannerButtonOneLink {
                        linkUrlAddress
                    }
                    bannerButtonOneText
                    bannerButtonTwoLink {
                        linkUrlAddress
                    }
                    bannerButtonTwoText
                    bannerHeading
                    bannerText
                    pageImage {
                        ...on ContentfulAsset {
                            contentful_id
                            __typename
                            gatsbyImageData(
                            aspectRatio: 1.5
                            cropFocus: CENTER
                            placeholder: BLURRED
                            width: 1000
                          )
                          title
                        }
                    }
                }
            }
        } 
        blogPosts: allContentfulBlogPost (filter: {slug: {ne: "sample-blog-post-do-not-delete"}}) {
            edges {
                node {
                    postTitle
                    postDate
                    slug 
                    sorting
                    category {
                        slug
                        title
                        sorting
                    }
                    featured
                    postMainImage {
                        gatsbyImageData(
                            aspectRatio: 1.5
                            cropFocus: CENTER
                            layout: CONSTRAINED
                            width: 600
                            placeholder: BLURRED
                        )
                        title
                        }
                        postShortDescription {
                        postShortDescription
                    }
                }
            }
        }
        siteSettings: allContentfulSiteGeneralSettings(
            filter: {siteTitle: {eq: "25 Spaces Real Estate"}}
        ) {
            edges {
            node {
                googleReviewRating
                googleReviewTotal
            }
            }
        }
    
    }
`


const Blog = ({ data: { blog, blogPosts, siteSettings } }) => {
    let location = useLocation();
    const [searchState, setSearchState] = useState(urlToBlogSearchState(location))
    const [allBlogs, setAllBlogs] = useState(cloneDeep(blogPosts.edges))
    const [blogs, setBlogs] = useState(cloneDeep(blogPosts.edges))
    const [query, setQuery] = useState(null)
    let categories = []
    let featuredList = allBlogs.filter((r) => r.node.featured == true);
    let newsArticles = allBlogs.filter((r) => (r.node.category && r.node.category?.slug == "blogs" && r.node.postDate != null));
    if(newsArticles.length > 0){
        newsArticles = orderBy(newsArticles, ['node.postDate'], ['desc']).slice(0,3); 
    }
    blogPosts.edges.map((n) => {
        if (n.node.category) {
            categories.push(n.node.category)
        }
    });

    let categoryCnt = categories.reduce((acc, { slug }) => {
        acc[slug] = acc[slug] || { slug: slug, count: 0 };
        acc[slug]['count'] += 1;
        return acc;
    }, {});

    categories = [...new Map(categories.map(item => [item['slug'], item])).values()];
    categories = orderBy(categories, ['sorting'], ['asc']); 
    const onSearch = () => {
        let queryUrl = (`/blogs/`)
        if (query) {
            queryUrl = (`/blogs/in-${query.replaceAll(" ", "-")}/`)
        }
        if (searchState.category) {
            queryUrl = `${queryUrl}category-${searchState.category}/`
        }
        navigate(queryUrl)
    }
    const onSearchClick = (e) => {
        onSearch()
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onSearch()
        }
    };
    useEffect(() => {
        let searchstat = urlToBlogSearchState(location)
        setSearchState(searchstat)
        setQuery(searchstat.search) 
        if (searchstat.category || searchstat.search) {
            let filterList = cloneDeep(allBlogs);
            if (searchstat.category) {
                filterList = filterList.filter((r) => (r.node.category && searchstat.category && r.node.category?.slug == searchstat.category));
            }
            if (searchstat.search) {
                filterList = filterList.filter((r) => (r.node.postTitle && searchstat.search && lowerCase(r.node.postTitle).includes(lowerCase(searchstat.search))));
            }
            setBlogs(filterList);
            console.log('blogData', filterList)
        } else {
            setBlogs(allBlogs);
        }


    }, [location])

    let catUrl = `/blogs`
    if (searchState.search) {
        catUrl = `/blogs/in-${searchState.search}`
    }

    return (
        <Layout>
            <SeoHelmet
                metaTitle="Read Qatar Real Estate News | 25 Spaces Real Estate"
                metaDescription="Whether you’re buying, selling, renting, or already living in Qatar, we have articles everyone can learn from, doha real estate news."
                ogimage={blog?.edges[0]?.node.pageHeaderImage}
            ></SeoHelmet>

            <GeneralPageSubHeader
                image={blog.edges[0].node.pageHeaderImage}
                imageAlt={blog.edges[0].node.pageHeaderImage.title}
                breadcrumb="Home - "
                pageName={blog.edges[0].node.title}
                bannerHeading={blog.edges[0].node.bannerHeading}
                bannerText={blog.edges[0].node.bannerText}
                bannerButtonOneLink={blog.edges[0].node.bannerButtonOneLink}
                bannerButtonOneText={blog.edges[0].node.bannerButtonOneText}
                bannerButtonTwoLink={blog.edges[0].node.bannerButtonTwoLink}
                bannerButtonTwoText={blog.edges[0].node.bannerButtonTwoText}
            ></GeneralPageSubHeader>
            <section className="blog-full-width-section">
                <div className="blog-sidebar mobile">
                    <div className='search'>
                        <SectionTitle
                            sectionNumber=""
                            sectionTitle={"Search"}
                            sectionTitleSpan={null}
                        ></SectionTitle>
                        <div className='search-box'>
                            <input class="search" value={query} type="text" name="searchbox" onChange={(e) => { setQuery(e.target.value) }} onKeyDown={handleKeyDown} placeholder="ENTER KEYWORD HERE" required />

                            <button type="button" onClick={onSearchClick} className='search-icon'> <img src={Glass} alt='mobile-img'  /></button>
                        </div>
                    </div>
                    <div className='categories'>
                        <SectionTitle
                            sectionNumber=""
                            sectionTitle={"categories"}
                            sectionTitleSpan={null}
                        ></SectionTitle>
                        <select name="categories" id="categories" className='form-control' onChange={(e) => {

                            if (e.target.value) {
                                navigate(`${catUrl}/category-${e.target.value}/`)
                            } else {
                                navigate(`${catUrl}/`)
                            }

                        }}>
                            <option > All Categories</option>
                            {categories.map((n) => {
                                return (
                                    <option value={n.slug}>{n.title} ({categoryCnt[n.slug].count})</option>
                                )
                            })
                            }
                        </select>
                    </div>
                </div>
                <div className="blog-list">
                    <div className="generic-page-content">
                        <SectionTitle
                            sectionNumber=""
                            sectionTitle={blog.edges[0].node.titleFirstBreak}
                            sectionTitleSpan={blog.edges[0].node.titleSecondBreak}
                        ></SectionTitle>
                    </div>
                    {blogs.length > 0 ?
                        <AllBlogPosts blogPosts={blogs}></AllBlogPosts>
                        : <p className='no-result'>No Result</p>
                    }

                </div>
                <div className="blog-sidebar desktop">
                    <div className='search search-desktop'>
                        <SectionTitle
                            sectionNumber=""
                            sectionTitle={"Search"}
                            sectionTitleSpan={null}
                        ></SectionTitle>
                        <div className='search-box'>
                            <input class="search" value={query} type="text" name="searchbox" onChange={(e) => { setQuery(e.target.value) }}  onKeyDown={handleKeyDown}placeholder="ENTER KEYWORD HERE" required />

                            <button type="button" onClick={onSearchClick} className='search-icon' > <img src={Glass} alt='mobile-img' /></button>
                        </div>
                    </div>
                    <div className='categories'>
                        <SectionTitle
                            sectionNumber=""
                            sectionTitle={"categories"}
                            sectionTitleSpan={null}
                        ></SectionTitle>
                        <ul>
                            {categories.map((n) => {
                                return (
                                    <li><Link to={`${catUrl}/category-${n.slug}/`}>{n.title} </Link> <span className='hr'></span><span className='count'>({categoryCnt[n.slug].count})</span></li>
                                )
                            })
                            }
                        </ul>
                    </div>
                    {featuredList && featuredList.length > 0 &&
                        <div className='featured-list'>
                            <SectionTitle
                                sectionNumber=""
                                sectionTitle={"Featured Posts"}
                                sectionTitleSpan={null}
                            ></SectionTitle>
                            <div className='datalist'>
                                {featuredList.map((n) => {
                                    return (
                                        <>
                                            <div className='list'>
                                                <GatsbyImage image={getImage(n.node.postMainImage)} alt={n.node.postMainImage.title} />

                                                <Link to={`/blog/${n.node.slug}/`}>{n.node.postTitle}</Link>
                                            </div>
                                            <span className='hr'></span>
                                        </>

                                    )
                                })
                                }
                            </div>
                        </div>
                    }
                    {newsArticles && newsArticles.length > 0 &&
                        <div className='featured-list'>
                            <SectionTitle
                                sectionNumber=""
                                sectionTitle={"News Article"}
                                sectionTitleSpan={null}
                            ></SectionTitle>
                            <div className='datalist'>
                                {newsArticles.map((n) => {
                                    const d = new Date(n.node?.postDate)
                                    var date = d.toLocaleDateString("en-UK", {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                    });
                                    return (
                                        <>
                                            <div className='list'>
                                                <GatsbyImage image={getImage(n.node.postMainImage)} alt={n.node.postMainImage.title} />
                                                <div className='withdate'>
                                                    <Link to={`/blog/${n.node.slug}/`}>{n.node.postTitle}</Link>
                                                    <p className='blog-published-date'>{date}</p>
                                                </div>


                                            </div>
                                            <span className='hr'></span>
                                        </>

                                    )
                                })
                                }
                            </div>
                        </div>
                    }
                    <div className='home-reviews-section'>
                        <SectionTitle
                            sectionNumber=""
                            sectionTitle={"WHAT OUR CLIENTS ARE SAYING"}
                            sectionTitleSpan={null}
                        ></SectionTitle>
                        <a href='https://www.google.com/search?q=25+spaces+real+estate&rlz=1C5CHFA_enFR1015FR1015&oq=25+spaces+&aqs=chrome.0.0i355i512j46i175i199i512j69i57j0i512j0i10i22i30j69i60l2j69i61.8433j0j7&sourceid=chrome&ie=UTF-8#lrd=0x3e45c4b871e3d2fd:0xf54afa7d6fa0189,1,,,'>

                            <StaticImage
                                src={"../../images/google-review-logo.svg"}
                                alt="Google Review Image"
                                placeholder="blurred"
                            />
                        </a>
                        <p><strong>{siteSettings.edges[0].node.googleReviewRating}/5</strong> Rating from {siteSettings.edges[0].node.googleReviewTotal} customer Reviews</p>
                    </div>

                </div>
            </section>
            <BlogNewsLetter></BlogNewsLetter>

        </Layout >
    )
}

export default Blog